import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" />
    <div className="thank-you-wrapper valueprop section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="text-white font-weight-bold text-center mb-3 h-ls">
              <strong>Thank You For Contacting Us</strong>
            </h1>
            <h5 className="text-center">
              Gray Hawk offers processes &amp; technology that are one of a
              kind.
            </h5>
            <div className="text-center mt-5 ">
              <div className="mb-3">
                <Link to="/" type="button" className="request-btn mr-3">
                  BACK To HOME
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
